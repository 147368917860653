import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DynZone from "../components/DynZone"
import CommentsFB from "../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Libros2 = ({ data }) => (
  <Layout>
    <GatsbySeo
      title={data.strapi.libro.nombre}
      titleTemplate="%s | Eduardo Ceballos"
      description={data.strapi.libro.descripcion}
      canonical={`https://eduardoceballos.com/libros/${data.strapi.libro.uid}`}
      openGraph={{
        url: `https://eduardoceballos.com/libros/${data.strapi.libro.uid}`,
        title: data.strapi.libro.nombre,
        description: data.strapi.libro.descripcion,
        images: [
          {
            url: data.strapi.libro.seo_img ? data.strapi.libro.seo_img.url : "",
            alt: data.strapi.libro.seo_img ? data.strapi.libro.seo_img.alt : "",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>{data.strapi.libro.nombre}</h1>
    <DynZone components={data.strapi.libro.DynZone}></DynZone>
    {/* <div>
      <center>
        <br />
        <br />
        <a href={data.strapi.libro.libro}>Enlace Directo</a>
        <br />
        <br />
        <a
          href={data.strapi.libro.libro}
          download=""
          target="_blank"
          rel="noopener noreferrer"
        >
          Click aqui para descargar
        </a>
      </center>
      <iframe
        title="libro"
        width="100%"
        height="965"
        src={data.strapi.libro.libro}
        frameborder="1"
        allowtransparency=""
        allowfullscreen=""
      ></iframe>
    </div> */}
    <CommentsFB></CommentsFB>
  </Layout>
)

export const query = graphql`
  query libros($id: ID!) {
    strapi {
      libro(id: $id) {
        uid
        nombre
        libro
        seo_img {
          url
          provider_metadata
        }
        descripcion
        DynZone {
          ... on SP_ComponentComunesFotoIzquierda {
            id
            __typename
            texto5
            foto {
              provider_metadata
              alternativeText
            }
          }
          ... on SP_ComponentComunesTexto {
            id
            __typename
            texto
          }
          ... on SP_ComponentComunesFoto {
            id
            __typename
            foto {
              provider_metadata
              alternativeText
            }
          }
          ... on SP_ComponentComunesAutores {
            __typename
            id
            autores {
              id
              nombre
              foto {
                caption
                alternativeText
                provider_metadata
              }
            }
          }
        }
      }
    }
  }
`

export default Libros2
